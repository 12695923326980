
export const Links = (props) => {
    return (
        <div id="links">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 justify-content-center text-center">
                    <a href="https://www.redpiso.es/oficina/inmobiliaria-penagrande/venta" class="btn btn-danger btn-lg" role="button">Venta</a>

                    </div>
                    <div className="col-md-4 justify-content-center text-center">
                    <a href="https://www.redpiso.es/oficina/inmobiliaria-penagrande/alquiler" class="btn btn-danger btn-lg" role="button">Alquiler</a>
                    </div>
                    <div className="col-md-4 justify-content-center text-center">
                    <a href="https://www.redpiso.es/oficina/inmobiliaria-penagrande" class="btn btn-danger btn-lg" role="button">Otros</a>
                    </div>
                </div>
            </div>
        </div>
    )
}