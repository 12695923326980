// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAY_5LYFhgm7HqzUAszuU8pbjIq1IzeQXo",
  authDomain: "redpiso-landing.firebaseapp.com",
  projectId: "redpiso-landing",
  storageBucket: "redpiso-landing.appspot.com",
  messagingSenderId: "302389566670",
  appId: "1:302389566670:web:e876e32fe4f08a52ac449f",
  measurementId: "G-PCMBGXPT7D"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage(app);