import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { InfoSection } from "./components/info_section";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import { collection,doc, getDoc } from "firebase/firestore";
import { Links } from "./components/links";
import { db } from "./firebase";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  // const [landingPageData, setLandingPageData] = useState({});
  const [info, setInfo] = useState();
  const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   setLandingPageData(JsonData);
  // }, []);

  useEffect(() => {
    loadData();
  },[]);

  const id = () => {
    const url = window.location.href;
    
    let id;
    if (url.includes("localhost")) {
      id = "penagrande";
      console.log("Localhost");
    }else {
      let regex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n]+)/;
      let match = url.match(regex);
      id = match[1].split('.')[0];
      console.log("Load id " + id);
    }
    return id;
  };
 
  const loadData = async () => {
    const documentReference = doc(db, "info", id());
    const documentSnaphost = await getDoc(documentReference);
    if (!documentSnaphost.exists()) {
      console.log('No such document!');
      setLoading(false);
    }
    const data = documentSnaphost.data();
    if (data) {
      setInfo(data);
      setLoading(false);
    }
    console.log(data);
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!info) {
    return <div>Not found</div>;
  }

  return (
    <div>
      <Navigation info={info} />
      <InfoSection info={info} id={id()}/>
      
      {/* <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      <Gallery data={landingPageData.Gallery} />
      <Testimonials data={landingPageData.Testimonials} />
      <Team data={landingPageData.Team} />
      <Contact data={landingPageData.Contact} /> */}
    </div>
  );
};

export default App;
