import React, { useState, useEffect } from "react";
import { storage } from "../firebase";
import { getDownloadURL, ref } from "firebase/storage";
import { Links } from "./links";

export const InfoSection = (props) => {
    const [imageURL, setImageURL] = useState({});
    useEffect(() => {
      loadImageUrl();
    },[]);
    const loadImageUrl = async () => {
    const reference = ref(storage, "landing/" + props.id + ".jpg");
      const url = await getDownloadURL(reference);
      setImageURL(url);
    }
  return (
    <div id="about" >
      <h1 className="text-center">{"Redpiso " + (props.info ? props.info.name : "")}</h1>
      <p> </p>
      <div className="container">
      
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src={imageURL ?? "img/about.jpg"} className="img-responsive" alt="" />{" "}
            <p></p>
            <div className="list-style">
            <div className="col-md-12  contact-info">
            <div className="contact-item">
              <h3>Contacto</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Dirección 
                </span>
                <div class="d-grid gap-3">
<div>{props.info.address1 ? props.info.address1 : ""}</div>
<div>{props.info.address2 ? props.info.address2 : ""}</div>
<div>{props.info.city ? props.info.city : ""}</div>
                </div>
               
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Teléfono
                </span>{" "}
                <div>{props.info.phone ? props.info.phone: ""}</div>
              
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                <div>
                {props.info.email ? props.info.email: ""}
                </div>
              </p>
            </div>
          </div>
                
              </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Sobre nosotros</h2>
              <p>{props.info ? props.info.description : "Cargando..."}</p>
              {/* <h3>¿Por qué elegirnos?</h3> */}
              {/* <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    
                    {props.info.why1
                      ? props.info.why1.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : ""}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.info.why2
                      ? props.info.why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : ""}
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Links info={props.info}/>
    </div>
  );
};
